.heroimage {
  width: 100%;
  height: 100%;
  position: relative;

  &.animate {
    // opacity: 0;
    will-change: transform;
    -webkit-transform: translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform: translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform: translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    transform: translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(0) skew(0, 0);
  }

  @media screen and (min-width: 1440px) {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
  }

  @media screen and (max-width: 767px) {
    z-index: 0;
    order: -1;
    width: 100vw;
  }

  .figma-cursor-1 {
    width: 90px;
    position: absolute;

    @media screen and (min-width: 1440px) {
      z-index: 1;
    }

    @media screen and (max-width: 991px) {
      width: 50px;
      transform: translate(-15px, 50px);
    }
  }

  .figma-cursor-1.cursor {
    inset: 50% 0% 0%;

    @media screen and (min-width: 1920px) {
      top: 50%;
      transform: none;
    }
  }

  @media screen and (max-width: 767px) {
    .figma-cursor-1.cursor.mobile {
      width: 90px;
      top: 25%;
      transform: none;
    }
  }

  @media screen and (max-width: 479px) {
    .figma-cursor-1.cursor.mobile {
      width: 70px;
    }
  }
}
