.nav-wrapper {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  z-index: 2;
  flex-flow: column;
  max-width: 1283px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  position: relative;
}

.nav-wrapper.nav-container {
  z-index: 9;
  -webkit-backdrop-filter: blur(60px);
  backdrop-filter: blur(60px);
  background-color: #16121e33;
  border-radius: 300px;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
  margin-right: 16px;
  padding: 11px 18px;
  display: flex;
  overflow: hidden;
}

.navbar {
  color: #f3f0f5;
  z-index: 10;
  background-color: #0000;
  margin-top: 42px;
  position: fixed;
  inset: 0% 0% auto;
}

.nav-drawer {
  display: block;
}

.drawer-container {
  grid-column-gap: 22px;
  grid-row-gap: 22px;
  display: flex;
}

.logo {
  width: 160px;
  height: 24px;
}

.left-content {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  display: flex;
}

.right-content {
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  display: flex;
}

.navigation {
  grid-column-gap: 22px;
  grid-row-gap: 22px;
  display: flex;
}

.nav-link-full {
  cursor: pointer;
  color: #fff;
  padding: 5px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  line-height: 16.94px;
  text-decoration: none;
}

@media screen and (min-width: 1440px) {
  .nav-wrapper.nav-container {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    grid-template-rows: auto;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
    display: flex;
  }

  .navbar {
    background-color: #0000;
  }

  .left-content {
    width: 100%;
  }

  .right-content {
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    display: flex;
  }
}

@media screen and (max-width: 991px) {
  .navbar {
    margin-top: 37px;
    padding: 10px 5px;
  }

  .nav-drawer {
    -webkit-backdrop-filter: blur(40px);
    backdrop-filter: blur(40px);
    z-index: 3;
    background-color: #23193f80;
    background-image: url("../../../images/bg-element.svg");
    background-position: 50%;
    background-size: 200% 200%;
    background-attachment: fixed;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
  }

  .drawer-container {
    background-color: #0f0c1499;
    border-radius: 20px;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    min-width: 250px;
    margin: 20% auto auto;
    padding: 24px;
    display: flex;
  }

  .left-content {
    justify-content: center;
    align-items: center;
  }

  .right-content {
    width: auto;
  }

  .navigation {
    display: none;
  }

  .nav-link-full {
    font-size: 22px;
    line-height: 26.63px;
  }

  .nav-link-full:hover {
    color: #d8d8d8;
  }
}

@media screen and (max-width: 767px) {
  .nav-drawer {
    z-index: 8;
  }

  .drawer-container {
    margin-top: 30%;
  }
}

@media screen and (max-width: 479px) {
  .nav-wrapper.nav-container {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }

  .navbar {
    padding-left: 0;
    padding-right: 0;
  }

  .nav-drawer {
    z-index: 6;
  }

  .drawer-container {
    margin-top: 40%;
  }

  .logo.w--current {
    padding-left: 0;
  }

  .left-content {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
}
