.mobile__iphone-1 {
  &.animate {
    opacity: 0;
    -webkit-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(null)
      rotateY(18deg) rotateZ(8deg) skew(0, 0);
    -moz-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(null)
      rotateY(18deg) rotateZ(8deg) skew(0, 0);
    -ms-transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(null)
      rotateY(18deg) rotateZ(8deg) skew(0, 0);
    transform: translate3d(null, 0px, 0) scale3d(1, 1, 1) rotateX(null)
      rotateY(18deg) rotateZ(8deg) skew(0, 0);
    transform-style: preserve-3d;
  }
}

.mobile__iphone-2 {
  &.animate {
    opacity: 0;
    -webkit-transform: translate3d(null, 25px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(4deg) skew(0, 0);
    -moz-transform: translate3d(null, 25px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(4deg) skew(0, 0);
    -ms-transform: translate3d(null, 25px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(4deg) skew(0, 0);
    transform: translate3d(null, 25px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(4deg) skew(0, 0);
  }
}

.mobile__iphone-3 {
  &.animate {
    -webkit-transform: translate3d(0, 45px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform: translate3d(0, 45px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform: translate3d(0, 45px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    transform: translate3d(0, 45px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(0) skew(0, 0);
  }
}

.mobile__iphone-4 {
  &.animate {
    opacity: 0;
    -webkit-transform: translate3d(84px, 0px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(-4deg) skew(0, 0);
    -moz-transform: translate3d(84px, 0px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(-4deg) skew(0, 0);
    -ms-transform: translate3d(84px, 0px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(-4deg) skew(0, 0);
    transform: translate3d(84px, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(-4deg) skew(0, 0);
  }
}

.mobile__iphone-5 {
  &.animate {
    opacity: 0;
    -webkit-transform: translate3d(0px, 0px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(-8deg) skew(0, 0);
    -moz-transform: translate3d(0px, 0px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(-8deg) skew(0, 0);
    -ms-transform: translate3d(0px, 0px, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(-8deg) skew(0, 0);
    transform: translate3d(0px, 0px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(-8deg) skew(0, 0);
  }
}
