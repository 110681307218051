.app__item {
  cursor: pointer;
  display: flex;
  position: relative;

  &.active {
    position: relative;

    .progress-container {
      display: block;
    }

    .progressbar {
      -webkit-transition: height 8s linear !important;
      -moz-transition: height 8s linear !important;
      -o-transition: height 8s linear !important;
      transition: 8s height linear !important;
      height: 100% !important;
    }

    .app__item-content {
      .app__card__title {
        color: #fb68a8;
      }

      .app__card__text {
        color: #ffffff;
        opacity: 100%;
        display: block;
        max-height: 100%;
      }
    }
  }

  .progress-container {
    background-color: #1a1d1f;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    align-self: stretch;
    height: 100%;
    position: absolute;

    .progressbar {
      background-color: #fb68a8;
      border-radius: 8px;
      width: 6px;
      height: 0%;
      position: absolute;
      inset: 0% 0% auto;
    }
  }

  .app__item-content {
    margin-left: 26px;

    .app__card__title {
      color: #9e9e9e;
      margin-top: 0;
      font-family: Inter, sans-serif;
      font-size: 16px;

      &.active {
        color: #fb68a8;
      }
    }

    .app__card__text {
      color: #9e9e9e;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;

      max-height: 0px;
      overflow: hidden;
      opacity: 0;

      &.active {
        color: #f3f0f5;
      }
    }
  }
}
