.section-container {
  padding-top: 78px;
  font-family: Inter, sans-serif;

  .container {
    z-index: 1;
    flex-flow: column;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    max-width: 1283px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    display: flex;
    overflow: hidden;

    @media screen and (min-width: 1440px) {
    }

    @media screen and (min-width: 1280px) {
      .container {
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }

    @media screen and (max-width: 767px) {
      .container {
        padding-right: 40px;
      }
    }
  }
}
