.root {
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.input {
  border-radius: 20px;
  padding: 12px 12px;
  border: none;
  margin-bottom: 10px;
  background-color: #fefefe;
  color: black;
}

.button {
  padding: 6px 12px;
  border-radius: 20px;
  border: none;
  background: rgb(79, 206, 93);
  color: rgb(255, 255, 255);
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
  margin: 0 20px;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 1px;
}
