.section-hero {
  background-image: url("../../../../images/bg-gradient.svg");
  background-position: 50%;
  background-size: cover;
  border-radius: 0 0 0 24px;
  justify-content: center;
  align-items: stretch;
  height: 100%;
  min-height: 650px;
  max-height: 882px;
  padding-top: 78px;
  display: flex;
  overflow: hidden;

  .container {
    z-index: 1;
    flex-flow: column;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    max-width: 1283px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 40px;
    display: flex;
    overflow: hidden;

    &.hero-container {
      overflow: unset;
      grid-template-rows: auto;
      grid-template-columns: 1fr 1fr;
      grid-auto-columns: 1fr;
      grid-auto-flow: row;
      place-items: center;
      padding-bottom: 0;
      display: grid;
    }
  }

  @media screen and (min-width: 1920px) {
    min-height: 100%;
    max-height: 100%;
  }

  @media screen and (min-width: 1440px) {
    height: 78vh;
    position: relative;

    .container {
      &.hero-container {
        grid-template-columns: 1fr 1fr;
        grid-auto-columns: 1fr;
        max-width: 1283px;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    min-height: 800px;
    position: relative;

    .container {
      padding-top: 40px;
      padding-bottom: 40px;

      &.hero-container {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media screen and (max-width: 991px) {
    min-height: 500px;

    .container {
      &.hero-container {
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        grid-template-columns: 0.9fr 1fr;
        padding-top: 0;
      }
    }
  }

  @media screen and (max-width: 767px) {
    background-image: none;
    background-size: auto;
    border-radius: 0 0 24px 24px;
    min-height: 90vh;

    .container {
      padding-right: 40px;

      &.hero-container {
        padding-bottom: 120px;
        padding-left: 0;
        padding-right: 0;
        display: flex;
        position: relative;
      }
    }
  }

  @media screen and (max-width: 479px) {
    .container {
      &.hero-container {
        padding-bottom: 70px;
      }
    }
  }
}
